import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Checkbox, Notification } from 'hds-react';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/checkbox/customisation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Notification type="alert" label="Checkbox customisation works differently!" className="siteNotification" mdxType="Notification">
  Checkbox is an older HDS component and therefore its customisation works slightly differently. Instead of the 
  <code>theme</code> property, you need to use the <code>style</code> property. This will be fixed in a future update.
    </Notification>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`In Core version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes in the HTML to customise the component.`}</p>
    <p>{`In React version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`className`}</inlineCode>{` properties to customise the component.`}</p>
    <p>{`See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size of the checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--icon-scale`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale of the checkbox check icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-width`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Width of the checkbox border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--outline-width`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Width of the checkbox focus border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--background-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the checkbox background when selected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--background-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the checkbox background when hovered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-color-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the checkbox border when selected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-color-selected-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the checkbox border when selected and hovered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-color-selected-focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the checkbox border when selected and focused`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--focus-outline-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the checkbox focus border`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{
  () => {
    const [checked, setChecked] = React.useState(false);
    const customStyles = {
      '--size': '40px',
      '--icon-scale': 0.6,
      '--border-width': '3px',
      '--outline-width': '4px',
      '--background-selected': 'var(--color-success)',
      '--background-hover': 'var(--color-success-dark)',
      '--border-color-selected': 'var(--color-success)',
      '--border-color-selected-hover': 'var(--color-success-dark)',
      '--border-color-selected-focus': 'var(--color-success)',
      '--focus-outline-color': 'var(--color-black-20)',
    };
    return (
      <Checkbox
        id="checkbox5"
        label="Label"
        style={customStyles}
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
    );
  };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div
  class="hds-checkbox"
  style="--size: 40px; --icon-scale: 0.6; --border-width: 3px; --outline-width: 4px;
  --background-selected: var(--color-success); --background-hover: var(--color-success-dark);
  --border-color-selected: var(--color-success); --border-color-selected-hover: var(--color-success-dark);
  --border-color-selected-focus: var(--color-success); --focus-outline-color: var(--color-black-20);"
>
  <input type="checkbox" id="checkbox1" class="hds-checkbox__input" value="foo" />
  <label for="checkbox1" class="hds-checkbox__label">Label</label>
</div>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      